<template>
  <div style="margin-top: 10px;">
    <el-form
      ref="contentForm"
      :rules="rules"
      :model="contentForm"
      label-position="top"
      size="large"
      v-loading="isLoading"
      class="form-container"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Default Account Fee')"
            prop="default_account_fee"
          >
            <el-input v-model="contentForm.default_account_fee"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item>
            <el-button
              v-show="canWrite()"
              @click="submitForm"
              class="submitBtn"
              >{{ __("Save") }}</el-button
            >
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "AccountPage",
  props: {
    activeTab: {
      type: String,
      required: false,
      default: ""
    },
    content: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapState("systemsettings", {
      isLoading: state => state.isLoading,
      contents: state => state.settings
    })
  },
  data() {
    return {
      contentForm: _.cloneDeep(this.content),
      rules: {
        default_account_fee: [
          {
            required: true,
            trigger: "blur",
            message: __("Default account fee is required")
          }
        ]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          let content = this.content;
          let contentForm = this.contentForm;
          this.$emit("update-settings", {
            content,
            contentForm
          });
        }
      });
    }
  },
  watch: {
    content: {
      immediate: true,
      handler: function(val) {
        if (val !== null) {
          this.contentForm = _.cloneDeep(this.content);
        }
      }
    },
    activeTab: {
      immediate: true,
      deep: true,
      handler: async function(val, oldVal) {
        if (oldVal !== val && val === "account") {
          this.contentForm = _.cloneDeep(this.content);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";
</style>
