<template>
  <el-container class="h-full">
    <el-container>
      <el-main>
        <div style="height: 50px; ">
          <page-header
            style="max-width: 90%; padding-left: 0.7rem"
            :title="__('Edit Settings')"
          />
        </div>
        <el-tabs
          v-model="activeName"
          style="margin-top: 10px; padding:1.5rem 1.5rem 2rem 0.7rem;"
          v-show="this.canRead()"
        >
          <el-tab-pane :label="__('Connect')" name="connect">
            <connect
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </connect>
          </el-tab-pane>
          <el-tab-pane :label="__('Web Security')" name="web-security">
            <web-security
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </web-security>
          </el-tab-pane>
          <el-tab-pane :label="__('System')" name="system">
            <system
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </system>
          </el-tab-pane>
          <el-tab-pane :label="__('Workflow')" name="workflow">
            <workflow
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </workflow>
          </el-tab-pane>
          <el-tab-pane :label="__('Email Address')" name="email-address">
            <email-address
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </email-address>
          </el-tab-pane>
          <el-tab-pane :label="__('Account')" name="account">
            <account
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </account>
          </el-tab-pane>
          <el-tab-pane :label="__('Support')" name="support">
            <support
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </support>
          </el-tab-pane>
          <el-tab-pane :label="__('Migration')" name="migration">
            <migration
              :active-tab="activeName"
              :content="contents"
              @update-settings="handleUpdateSettings"
            >
            </migration>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
    <el-aside class="form-aside relative" :width="`${asideWidth}px`">
      <div class="aside-content">
        <div style="font-size: 14px;padding: 30px 15px;color: #A0A8B5">
          {{ asideText }}
        </div>
      </div>
      <slot name="form"></slot>
    </el-aside>
  </el-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Account from "./pages/Account";
import connect from "./pages/connect";
import emailAddress from "./pages/emailAddress";
import support from "./pages/support";
import system from "./pages/system";
import webSecurity from "./pages/webSecurity";
import workflow from "./pages/workflow";
import migration from "./pages/MigrationSettings.vue";
import PageHeader from "@/components/PageHeader";
import _ from "lodash";

export default {
  name: "SettingsIndex",
  components: {
    PageHeader,
    Account,
    connect,
    emailAddress,
    support,
    system,
    webSecurity,
    workflow,
    migration
  },
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: ""
    },
    asideButtonText: {
      default: "Create"
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 274
    }
  },
  data() {
    return {
      activeName: "connect"
    };
  },

  computed: {
    ...mapState("systemsettings", {
      contents: state => state.settings
    })
  },

  methods: {
    ...mapActions("systemsettings", {
      getSystemSettings: "getSettings",
      updateSystemSettings: "updateSystemSettings"
    }),
    handleUpdateSettings(data) {
      let process = this.updateSystemSettings;
      let diff = _.omitBy(data.contentForm, function(v, k) {
        return data.content[k] === v;
      });
      console.log(diff);
      if (_.values(diff).length > 0) {
        diff["setting_id"] = data.content.setting_id;
        process(diff)
          .then(data => {
            console.log(data);
            this.$message({
              type: "success",
              message: __("Settings updated successfully.")
            });
          })
          .catch(err => {
            this.isSubmitting = false;
            this.$message({
              type: "error",
              message: err.response.data.message
            });
          });
      }
    }
  },
  async created() {
    await this.getSystemSettings();
  }
};
</script>

<style lang="scss" scoped>
.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}
</style>
