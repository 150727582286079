<template>
  <div style="margin-top: 10px;">
    <el-form
      ref="contentForm"
      :rules="rules"
      :model="contentForm"
      label-position="top"
      size="large"
      v-loading="isLoading"
      class="form-container"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Send Email Login Fail X Times')"
            prop="send_email_login_fail_x_times"
          >
            <el-radio-group v-model="contentForm.send_email_login_fail_x_times">
              <el-radio label="Yes">{{ __("Yes") }}</el-radio>
              <el-radio label="No">{{ __("No") }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Max Login Fail Times')"
            prop="max_login_fail_times"
          >
            <el-select
              style="width: 100%"
              v-model="contentForm.max_login_fail_times"
              :placeholder="__('Select')"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in failTimes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Duration For Lock Out')"
            prop="duration_for_lock_out"
          >
            <el-select
              style="width: 100%"
              v-model="contentForm.duration_for_lock_out"
              :placeholder="__('Select')"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in lockOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item>
            <el-button
              v-show="canWrite()"
              @click="submitForm"
              class="submitBtn"
              >{{ __("Save") }}</el-button
            >
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "webSecurity",
  props: {
    activeTab: {
      type: String,
      required: false,
      default: ""
    },
    content: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapState("systemsettings", {
      isLoading: state => state.isLoading,
      contents: state => state.settings
    })
  },
  data() {
    return {
      contentForm: _.cloneDeep(this.content),
      failTimes: [
        {
          label: __("1"),
          value: "1"
        },
        {
          label: __("2"),
          value: "2"
        },
        {
          label: __("3"),
          value: "3"
        },
        {
          label: __("4"),
          value: "4"
        },
        {
          label: __("5"),
          value: "5"
        },
        {
          label: __("6"),
          value: "6"
        },
        {
          label: __("7"),
          value: "7"
        },
        {
          label: __("8"),
          value: "8"
        },
        {
          label: __("9"),
          value: "9"
        },
        {
          label: __("10"),
          value: "10"
        }
      ],
      lockOptions: [
        {
          label: __("5 seconds"),
          value: "5"
        },
        {
          label: __("10 seconds"),
          value: "10"
        },
        {
          label: __("20 seconds"),
          value: "20"
        },
        {
          label: __("30 seconds"),
          value: "30"
        },
        {
          label: __("40 seconds"),
          value: "40"
        },
        {
          label: __("50 seconds"),
          value: "50"
        },
        {
          label: __("1 minute"),
          value: "60"
        },
        {
          label: __("2 minutes"),
          value: "120"
        },
        {
          label: __("3 minutes"),
          value: "180"
        },
        {
          label: __("4 minutes"),
          value: "240"
        },
        {
          label: __("5 minutes"),
          value: "300"
        },
        {
          label: __("6 minutes"),
          value: "360"
        },
        {
          label: __("7 minutes"),
          value: "420"
        },
        {
          label: __("8 minutes"),
          value: "480"
        },
        {
          label: __("9 minutes"),
          value: "540"
        },
        {
          label: __("10 minutes"),
          value: "600"
        },
        {
          label: __("20 minutes"),
          value: "1200"
        },
        {
          label: __("30 minutes"),
          value: "1800"
        },
        {
          label: __("1 hour"),
          value: "3600"
        }
      ],
      rules: {
        max_login_fail_times: [
          {
            required: true,
            trigger: "blur",
            message: __("Max login fail time is required")
          }
        ],
        duration_for_lock_out: [
          {
            required: true,
            trigger: "blur",
            message: __("Duration for lock out is required")
          }
        ]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          let content = this.content;
          let contentForm = this.contentForm;
          this.$emit("update-settings", {
            content,
            contentForm
          });
        }
      });
    }
  },
  watch: {
    content: {
      immediate: true,
      handler: function(val) {
        if (val !== null) {
          this.contentForm = _.cloneDeep(this.content);
        }
      }
    },
    activeTab: {
      immediate: true,
      deep: true,
      handler: async function(val, oldVal) {
        if (oldVal !== val && val === "web-security") {
          this.contentForm = _.cloneDeep(this.content);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";
</style>
