<template>
  <div style="margin-top: 10px;">
    <el-form
      ref="contentForm"
      :rules="rules"
      :model="contentForm"
      label-position="top"
      size="large"
      v-loading="isLoading"
      class="form-container"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Db Version')" prop="db_version">
            <el-input v-model="contentForm.db_version"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Max Screenpop Data Age')"
            prop="max_screenpop_data_age"
          >
            <el-input v-model="contentForm.max_screenpop_data_age"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Min Call Duration Minutes')"
            prop="min_call_duration_minutes"
          >
            <el-input v-model="contentForm.min_call_duration_minutes">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('New Service Provider Notification')"
            prop="send_to_new_service_provider_notification"
          >
            <el-radio-group
              v-model="contentForm.new_service_provider_notification"
            >
              <el-radio label="Yes">{{ __("Yes") }}</el-radio>
              <el-radio label="No">{{ __("No") }}</el-radio>
            </el-radio-group>

            <el-select
              style="width: 100%"
              v-model="contentForm.send_to_new_service_provider_notification"
              :placeholder="__('Select')"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in spNotifications"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('New Account Notification')"
            prop="send_to_new_account_notification"
          >
            <el-radio-group v-model="contentForm.new_account_notification">
              <el-radio label="Yes">{{ __("Yes") }}</el-radio>
              <el-radio label="No">{{ __("No") }}</el-radio>
            </el-radio-group>

            <el-select
              style="width: 100%"
              v-model="contentForm.send_to_new_account_notification"
              :placeholder="__('Select')"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in spNotifications"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Mailer type')" prop="mailer_type">
            <el-select
              style="width: 100%"
              v-model="contentForm.mailer_type"
              :placeholder="__('Select')"
              filterable
              default-first-option
            >
              <el-option
                v-for="item in mailerOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Studio Alerts Slack Notification')"
            prop="studio_alerts_slack_notification_webhook_url"
          >
            <el-radio-group
              v-model="contentForm.studio_alerts_slack_notification"
            >
              <el-radio label="Yes">{{ __("Yes") }}</el-radio>
              <el-radio label="No">{{ __("No") }}</el-radio>
            </el-radio-group>

            <el-input
              v-model="contentForm.studio_alerts_slack_notification_webhook_url"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Billing Alerts Slack Notification')"
            prop="billing_alerts_slack_notification_webhook_url"
          >
            <el-radio-group
              v-model="contentForm.billing_alerts_slack_notification"
            >
              <el-radio label="Yes">{{ __("Yes") }}</el-radio>
              <el-radio label="No">{{ __("No") }}</el-radio>
            </el-radio-group>

            <el-input
              v-model="
                contentForm.billing_alerts_slack_notification_webhook_url
              "
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item>
            <el-button
              v-show="canWrite()"
              @click="submitForm"
              class="submitBtn"
              >{{ __("Save") }}</el-button
            >
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "SystemComponent",
  props: {
    activeTab: {
      type: String,
      required: false,
      default: ""
    },
    content: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  computed: {
    ...mapState("systemsettings", {
      isLoading: state => state.isLoading,
      contents: state => state.settings
    })
  },
  data() {
    return {
      contentForm: _.cloneDeep(this.content),
      spNotifications: [
        {
          label: __("Email Attached To Ticket System"),
          value: "email_attached_to_ticket_system"
        },
        {
          label: __("Email To Support Team"),
          value: "email_to_support_team"
        }
      ],
      mailerOptions: [
        {
          label: __("SMTP"),
          value: "smtp"
        }
      ],
      rules: {
        db_version: [
          {
            required: true,
            trigger: "blur",
            message: __("DB Version is required")
          }
        ],
        max_screenpop_data_age: [
          {
            required: true,
            trigger: "blur",
            message: __("Max screenpop data age is required")
          }
        ],
        min_call_duration_minutes: [
          {
            required: true,
            trigger: "blur",
            message: __("Min call duration minutes are required")
          }
        ],
        send_to_new_service_provider_notification: [
          {
            required: true,
            trigger: "blur",
            message: __("Send to new service provider notification required")
          }
        ],
        send_to_new_account_notification: [
          {
            required: true,
            trigger: "blur",
            message: __("Send to new account notification required")
          }
        ],
        mailer_type: [
          {
            required: true,
            trigger: "blur",
            message: __("Mailer type required")
          }
        ],
        studio_alerts_slack_notification_webhook_url: [
          {
            required: true,
            trigger: "blur",

            // eslint-disable-next-line
            message: __("Studio alerts slack notification webhook url is required")
          }
        ]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          let content = this.content;
          let contentForm = this.contentForm;
          this.$emit("update-settings", {
            content,
            contentForm
          });
        }
      });
    }
  },

  watch: {
    content: {
      immediate: true,
      handler: function(val) {
        if (val !== null) {
          this.contentForm = _.cloneDeep(this.content);
        }
      }
    },
    activeTab: {
      immediate: true,
      deep: true,
      handler: async function(val, oldVal) {
        if (oldVal !== val && val === "system") {
          this.contentForm = _.cloneDeep(this.content);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/form_common.scss";
</style>
